import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "advanced-hooks"
    }}>{`Advanced Hooks`}</h1>
    <h2 {...{
      "id": "context"
    }}>{`Context`}</h2>
    <p>{`Often, a hook is going to need a value out of context. The `}<inlineCode parentName="p">{`useContext`}</inlineCode>{` hook is really good for
this, but it will often require a `}<inlineCode parentName="p">{`Provider`}</inlineCode>{` to be wrapped around the component using the hook. We
can use the `}<inlineCode parentName="p">{`wrapper`}</inlineCode>{` option for `}<inlineCode parentName="p">{`renderHook`}</inlineCode>{` to do just that.`}</p>
    <p>{`Let's change the `}<inlineCode parentName="p">{`useCounter`}</inlineCode>{` example from the `}<a parentName="p" {...{
        "href": "/usage/basic-hooks"
      }}>{`Basic Hooks section`}</a>{` to get a
`}<inlineCode parentName="p">{`step`}</inlineCode>{` value from context and build a `}<inlineCode parentName="p">{`CounterStepProvider`}</inlineCode>{` that allows us to set the value:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React, { useState, useContext, useCallback } from 'react'

const CounterStepContext = React.createContext(1)

export const CounterStepProvider = ({ step, children }) => (
  <CounterStepContext.Provider value={step}>{children}</CounterStepContext.Provider>
)

export function useCounter(initialValue = 0) {
  const [count, setCount] = useState(initialValue)
  const step = useContext(CounterStepContext)
  const increment = useCallback(() => setCount((x) => x + step), [step])
  const reset = useCallback(() => setCount(initialValue), [initialValue])
  return { count, increment, reset }
}
`}</code></pre>
    <p>{`In our test, we simply use `}<inlineCode parentName="p">{`CounterStepProvider`}</inlineCode>{` as the `}<inlineCode parentName="p">{`wrapper`}</inlineCode>{` when rendering the hook:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { renderHook, act } from '@testing-library/react-hooks'
import { CounterStepProvider, useCounter } from './counter'

test('should use custom step when incrementing', () => {
  const wrapper = ({ children }) => <CounterStepProvider step={2}>{children}</CounterStepProvider>
  const { result } = renderHook(() => useCounter(), { wrapper })

  act(() => {
    result.current.increment()
  })

  expect(result.current.count).toBe(2)
})
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`wrapper`}</inlineCode>{` option will accept any React component, but it `}<strong parentName="p">{`must`}</strong>{` render `}<inlineCode parentName="p">{`children`}</inlineCode>{` in order for
the test component to render and the hook to execute.`}</p>
    <h3 {...{
      "id": "providing-props"
    }}>{`Providing Props`}</h3>
    <p>{`Sometimes we need to test a hook with different context values. By using the `}<inlineCode parentName="p">{`initialProps`}</inlineCode>{` option
and the new props of `}<inlineCode parentName="p">{`rerender`}</inlineCode>{` method, we can easily do this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { renderHook, act } from '@testing-library/react-hooks'
import { CounterStepProvider, useCounter } from './counter'

test('should use custom step when incrementing', () => {
  const wrapper = ({ children, step }) => (
    <CounterStepProvider step={step}>{children}</CounterStepProvider>
  )
  const { result, rerender } = renderHook(() => useCounter(), {
    wrapper,
    initialProps: {
      step: 2
    }
  })

  act(() => {
    result.current.increment()
  })

  expect(result.current.count).toBe(2)

  /**
   * Change the step value
   */
  rerender({ step: 8 })

  act(() => {
    result.current.increment()
  })

  expect(result.current.count).toBe(10)
})
`}</code></pre>
    <p>{`Note the `}<inlineCode parentName="p">{`initialProps`}</inlineCode>{` and the new props of `}<inlineCode parentName="p">{`rerender`}</inlineCode>{` are also accessed by the callback function
of the `}<inlineCode parentName="p">{`renderHook`}</inlineCode>{` which the wrapper is provided to.`}</p>
    <h3 {...{
      "id": "eslint-warning"
    }}>{`ESLint Warning`}</h3>
    <p>{`It can be very tempting to try to inline the `}<inlineCode parentName="p">{`wrapper`}</inlineCode>{` variable into the `}<inlineCode parentName="p">{`renderHook`}</inlineCode>{` line, and
there is nothing technically wrong with doing that, but if you are using
`}<a parentName="p" {...{
        "href": "https://eslint.org/"
      }}><inlineCode parentName="a">{`eslint`}</inlineCode></a>{` and
`}<a parentName="p" {...{
        "href": "https://github.com/yannickcr/eslint-plugin-react"
      }}><inlineCode parentName="a">{`eslint-plugin-react`}</inlineCode></a>{`, you will see a linting
error that says:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Component definition is missing display name`}</p>
    </blockquote>
    <p>{`This is caused by the `}<inlineCode parentName="p">{`react/display-name`}</inlineCode>{` rule and although it's unlikely to cause you any issues,
it's best to take steps to remove it. If you feel strongly about not having a separate `}<inlineCode parentName="p">{`wrapper`}</inlineCode>{`
variable, you can disable the error for the test file by adding a special comment to the top of the
file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`/* eslint-disable react/display-name */

import { renderHook, act } from '@testing-library/react-hooks'
import { CounterStepProvider, useCounter } from './counter'

test('should use custom step when incrementing', () => {
  const { result } = renderHook(() => useCounter(), {
    wrapper: ({ children }) => <CounterStepProvider step={2}>{children}</CounterStepProvider>
  })

  act(() => {
    result.current.increment()
  })

  expect(result.current.count).toBe(2)
})
`}</code></pre>
    <p>{`Similar techniques can be used to disable the error for just the specific line, or for the whole
project, but please take the time to understand the impact that disabling linting rules will have on
you, your team, and your project.`}</p>
    <h2 {...{
      "id": "async"
    }}>{`Async`}</h2>
    <p>{`Sometimes, a hook can trigger asynchronous updates that will not be immediately reflected in the
`}<inlineCode parentName="p">{`result.current`}</inlineCode>{` value. Luckily, `}<inlineCode parentName="p">{`renderHook`}</inlineCode>{` returns some utilities that allow the test to wait for
the hook to update using `}<inlineCode parentName="p">{`async/await`}</inlineCode>{` (or just promise callbacks if you prefer). The most basic
async utility is called `}<inlineCode parentName="p">{`waitForNextUpdate`}</inlineCode>{`.`}</p>
    <p>{`Let's further extend `}<inlineCode parentName="p">{`useCounter`}</inlineCode>{` to have an `}<inlineCode parentName="p">{`incrementAsync`}</inlineCode>{` callback that will update the `}<inlineCode parentName="p">{`count`}</inlineCode>{`
after `}<inlineCode parentName="p">{`100ms`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React, { useState, useContext, useCallback } from 'react'

export function useCounter(initialValue = 0) {
  const [count, setCount] = useState(initialValue)
  const step = useContext(CounterStepContext)
  const increment = useCallback(() => setCount((x) => x + step), [step])
  const incrementAsync = useCallback(() => setTimeout(increment, 100), [increment])
  const reset = useCallback(() => setCount(initialValue), [initialValue])
  return { count, increment, incrementAsync, reset }
}
`}</code></pre>
    <p>{`To test `}<inlineCode parentName="p">{`incrementAsync`}</inlineCode>{` we need to `}<inlineCode parentName="p">{`await waitForNextUpdate()`}</inlineCode>{` before making our assertions:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { renderHook } from '@testing-library/react-hooks'
import { useCounter } from './counter'

test('should increment counter after delay', async () => {
  const { result, waitForNextUpdate } = renderHook(() => useCounter())

  result.current.incrementAsync()

  await waitForNextUpdate()

  expect(result.current.count).toBe(1)
})
`}</code></pre>
    <p>{`Wrapping `}<inlineCode parentName="p">{`incrementAsync`}</inlineCode>{` in `}<inlineCode parentName="p">{`act()`}</inlineCode>{` is not necessary since the state updates happen asynchronously
during `}<inlineCode parentName="p">{`await waitForNextUpdate()`}</inlineCode>{`. The async utilities automatically wrap the waiting code in the
asynchronous `}<inlineCode parentName="p">{`act()`}</inlineCode>{` wrapper.`}</p>
    <p>{`For more details on the other async utilities, please refer to the
`}<a parentName="p" {...{
        "href": "/reference/api#asyncutils"
      }}>{`API Reference`}</a>{`.`}</p>
    <h3 {...{
      "id": "suspense"
    }}>{`Suspense`}</h3>
    <p>{`All the `}<a parentName="p" {...{
        "href": "/reference/api#async-utilities"
      }}>{`async utilities`}</a>{` will also wait for hooks that suspend
using `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/react-api.html#reactsuspense"
      }}>{`React's `}<inlineCode parentName="a">{`Suspense`}</inlineCode></a>{` functionality to
complete rendering.`}</p>
    <h2 {...{
      "id": "errors"
    }}>{`Errors`}</h2>
    <p>{`If you need to test that a hook throws the errors you expect it to, you can use `}<inlineCode parentName="p">{`result.error`}</inlineCode>{` to
access an error that may have been thrown in the previous render. For example, we could make the
`}<inlineCode parentName="p">{`useCounter`}</inlineCode>{` hook threw an error if the count reached a specific value:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React, { useState, useContext, useCallback } from 'react'

export function useCounter(initialValue = 0) {
  const [count, setCount] = useState(initialValue)
  const step = useContext(CounterStepContext)
  const increment = useCallback(() => setCount((x) => x + step), [step])
  const incrementAsync = useCallback(() => setTimeout(increment, 100), [increment])
  const reset = useCallback(() => setCount(initialValue), [initialValue])

  if (count > 9000) {
    throw Error("It's over 9000!")
  }

  return { count, increment, incrementAsync, reset }
}
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import { renderHook, act } from '@testing-library/react-hooks'
import { useCounter } from './counter'

it('should throw when over 9000', () => {
  const { result } = renderHook(() => useCounter(9000))

  act(() => {
    result.current.increment()
  })

  expect(result.error).toEqual(Error("It's over 9000!"))
})
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      